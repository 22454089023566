 @import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); 


body{
font-family: 'Urbanist', sans-serif;
background-color: #1B0F01;
}

@font-face {font-family: "GT Sectra Display Medium"; src: url("//db.onlinewebfonts.com/t/b207fae73e1bde668238f6d9d0598cc9.eot"); src: url("//db.onlinewebfonts.com/t/b207fae73e1bde668238f6d9d0598cc9.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/b207fae73e1bde668238f6d9d0598cc9.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/b207fae73e1bde668238f6d9d0598cc9.woff") format("woff"), url("//db.onlinewebfonts.com/t/b207fae73e1bde668238f6d9d0598cc9.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/b207fae73e1bde668238f6d9d0598cc9.svg#GT Sectra Display Medium") format("svg"); }
@font-face {font-family: "GT Sectra Display Light"; src: url("//db.onlinewebfonts.com/t/66d66b2194f4e2510a65d72509b6673f.eot"); src: url("//db.onlinewebfonts.com/t/66d66b2194f4e2510a65d72509b6673f.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/66d66b2194f4e2510a65d72509b6673f.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/66d66b2194f4e2510a65d72509b6673f.woff") format("woff"), url("//db.onlinewebfonts.com/t/66d66b2194f4e2510a65d72509b6673f.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/66d66b2194f4e2510a65d72509b6673f.svg#GT Sectra Display Light") format("svg"); }
@font-face {font-family: "GT Sectra Display Super"; src: url("//db.onlinewebfonts.com/t/8f5d911213d308eb53739263c41e6b6f.eot"); src: url("//db.onlinewebfonts.com/t/8f5d911213d308eb53739263c41e6b6f.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8f5d911213d308eb53739263c41e6b6f.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8f5d911213d308eb53739263c41e6b6f.woff") format("woff"), url("//db.onlinewebfonts.com/t/8f5d911213d308eb53739263c41e6b6f.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8f5d911213d308eb53739263c41e6b6f.svg#GT Sectra Display Super") format("svg"); }
@font-face {font-family: "GT Sectra Display Bold"; src: url("//db.onlinewebfonts.com/t/015ed00ca6e2a6c1a688b0dbc0dd37b9.eot"); src: url("//db.onlinewebfonts.com/t/015ed00ca6e2a6c1a688b0dbc0dd37b9.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/015ed00ca6e2a6c1a688b0dbc0dd37b9.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/015ed00ca6e2a6c1a688b0dbc0dd37b9.woff") format("woff"), url("//db.onlinewebfonts.com/t/015ed00ca6e2a6c1a688b0dbc0dd37b9.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/015ed00ca6e2a6c1a688b0dbc0dd37b9.svg#GT Sectra Display Bold") format("svg"); }
@font-face {font-family: "Gt"; src: url("//db.onlinewebfonts.com/t/8d026c963e4feb5053ef8b0cf5ca1910.eot"); src: url("//db.onlinewebfonts.com/t/8d026c963e4feb5053ef8b0cf5ca1910.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8d026c963e4feb5053ef8b0cf5ca1910.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8d026c963e4feb5053ef8b0cf5ca1910.woff") format("woff"), url("//db.onlinewebfonts.com/t/8d026c963e4feb5053ef8b0cf5ca1910.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8d026c963e4feb5053ef8b0cf5ca1910.svg#Gt") format("svg"); }